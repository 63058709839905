import { Injectable, Component, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import {setBrowser, isBrowser} from '../thirdparty/isBrowser'

declare var $:any;

@Injectable({providedIn: 'root'})
export class AppService {

  public static VIEWER_MODE :number =  0;
  public static USER_MODE :number =  1;
  public popuped = false;

  public telecommandeClose = true

  public isViewer = true;
  public isUser = false;

  public refreshAfterLogin = false

  public isBrowser = new BehaviorSubject<boolean>(null);

  currentMode:number;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
      this.currentMode = 0;
      AppService.VIEWER_MODE = 0;
      AppService.USER_MODE = 1;
      this.isViewer = true;
      this.isUser = false;
      this.isBrowser.next(isPlatformBrowser(platformId));
      setBrowser(this.isBrowser)
  }

  isBrowserOrNo(){
      return this.isBrowser
  }


  popupAccount(telecommandeClose=false): void{
    if(this.popuped == false || !this.popuped){
        this.showPopup(telecommandeClose);
    }
    else{
        this.hidePopup();
    }
  }

  hidePopup(){
      if(isBrowser()) $('#loginBackModal').addClass('hidden');
      if(isBrowser()) setTimeout(()=>{
          this.popuped = false;
          this.telecommandeClose = false
      }, 200);
  }

  showPopup(telecommandeClose=false, refreshAfterLogin=false){
      this.refreshAfterLogin = refreshAfterLogin
      if(telecommandeClose){
          this.telecommandeClose = true
      }
      this.popuped = true;
      if(isBrowser()) setTimeout(()=>{
          if(isBrowser()) $('#loginBackModal').removeClass('hidden');
      }, 10);
  }

  changeMode(mode){
    if(mode == AppService.VIEWER_MODE){
      this.isViewer = true;
      this.isUser = false;
    }else if(mode == AppService.USER_MODE){
      this.isViewer = false;
      this.isUser = true;
    }
    this.currentMode = mode;
  }

  getCurrentMode(){
    return this.currentMode;
  }

  isViewerMode(){

    return this.isViewer;
  }

  isUserMode(){
    return this.isUser
  }

  loadScript(url){
      return new Promise((resolve, reject) => {
        if(isBrowser()){
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            script.onload = () => {
                resolve(1);
            };
            script.onerror = () => {
                reject(new Error(`Script load error for ${url}`));
            };
            document.head.appendChild(script);
        }
        else{
            resolve(null)
        }
      });
  }

}
