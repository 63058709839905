<div #content>
  <div class="container py-4" *ngIf="display">
      <h2 class="text-center mb-4"><a [routerLink]="language.navigateAccordingToLang(['/blog'])" title="blog">{{"Articles à la une" | translate}}</a></h2>
      <div class="row">
          <div class="col-4" *ngFor="let post of posts">
              <div class="post rounded border" *ngIf="post">
                  <a *ngIf="!post.ytId" [routerLink]="language.navigateAccordingToLang([post.link])" [ngStyle]="{'background-image' : 'url('+post['img']+')'}" class="imgHeader mb-3" title="{{post.title}}"></a>
                  <a *ngIf="post.ytId" [routerLink]="language.navigateAccordingToLang([post.link])" [ngStyle]="{'background-image' : 'url(https://img.youtube.com/vi/'+post.ytId+'/maxresdefault.jpg)'}" class="imgHeader mb-3" title="{{post.title}}"></a>
                  <div class="informations mb-3 px-3">
                      <span class="date">{{language.getDate(post.createdDate)}}</span>
                      <div class="tag rounded px-2" *ngIf="!isRd && !isInterview">{{post.tag['title']}}</div>
                  </div>
                  <div class="actu-body px-3">
                      <a class="title mb-3" [routerLink]="language.navigateAccordingToLang([post.link])" title="{{post.title}}">{{post.title}}</a>
                      <p class="description" [innerHTML]="post['shortDesc']"></p>
                      <a [routerLink]="language.navigateAccordingToLang([post.link])" class="viewmore mb-3" title="{{post.title}}">{{'Voir plus' | translate}}</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
