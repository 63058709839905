import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Title, Meta } from '@angular/platform-browser';

import { LanguageService } from './language.service'

import { metadatas } from '../thirdparty/seo/metadatas'
import { environment } from '../../../environments/environment'

@Injectable({providedIn: 'root'})
export class MetadatasService {

    constructor(
        public title : Title,
        public meta : Meta,
        public language : LanguageService,
        @Inject(DOCUMENT) private document: any
    ) { }

    setMetadatas(url){
        this.setMetaTag('og:locale',((url.split('/')[0] == '/fr') ? 'fr_FR' : 'en_US'))
        this.setMetaTag('og:url', 'https://immersivefactory.com'+url);
        this.setMetaTag('og:site_name', 'Immersive Factory');

        var metadatas = this.getMetadatasInfos(url)
        if(metadatas){
            this.setMeta(metadatas)
        }
        else{
            this.setMeta({})
        }

        this.defineHrefLang(url)
    }

    setMeta(datas){
        var defaultDatas = {
            "title" : "VR EHS training for remote and onsite teams",
            "description" : "EHS training and event:  VR workshops, hazard spotting, quizz to raise awareness every day!",
            // "keywords" : "ehs training",
            "image" : "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/home.jpg",
            "og:type" : "website",
            "og:image:width" : "1200",
            "og:image:height" : "630",
            "og:image:type" : "image/jpeg",
            "twitter:card" : "summary",
            "twitter:creator" : "@immersivefactor",
            "twitter:site" : "@immersivefactor",
            "article:published_time" : null,
            "article:section" : null,
            "googlebot" : "all",
            "robots" : "all"
        }

        for(let attr of Object.keys(defaultDatas)){
            this.setMetaTag(attr, ((datas[attr]) ? datas[attr] : defaultDatas[attr]))
        }
    }

    setMetaTag(name, value){
        if(value){
            if(name == 'title'){
                this.title.setTitle(value)
                this.meta.updateTag({property: 'og:title', content: value})
                this.meta.updateTag({name: 'twitter:title', content: value})
            }
            else if(name=="description"){
                this.meta.updateTag({name: 'description', content: value})
                this.meta.updateTag({property: 'og:description', content: value})
                this.meta.updateTag({name: 'twitter:description', content: value})
            }
            // else if(name=="keywords"){
                // this.meta.updateTag({name: 'keywords', content: value})
            // }
            else if(name=="image"){
                this.meta.updateTag({property: 'og:image', content: value})
                this.meta.updateTag({name: 'twitter:image', content: value.replace('/original/', '/square_1500/')})
            }
            else if(name=="robots" || name.includes('twitter:')){
                this.meta.updateTag({name: name, content: value})
            }
            else{
                this.meta.updateTag({property: name, content: value})
            }
        }
        else{
            this.meta.removeTag("name='"+name+"'")
            this.meta.removeTag("property='"+name+"'")
        }

    }

    getMetadatasInfos(toCheck){
        var url = toCheck.split('?')[0]
        var urlSplitted = ((url.slice(0,1) == '/') ? url.slice(1) : url).split('/')
        var circularGet = (item,iUrl) =>{
            if(item[urlSplitted[iUrl]]){
                if(urlSplitted[iUrl+1]){
                    if(item[urlSplitted[iUrl]]['subs']){
                        if(item[urlSplitted[iUrl]]['subs']['*']){
                            return item[urlSplitted[iUrl]]['subs']['*']
                        }
                        else{
                            return circularGet(item[urlSplitted[iUrl]]['subs'], iUrl+1)
                        }
                    }
                    else{
                        return null
                    }
                }
                else{
                    return item[urlSplitted[iUrl]]
                }
            }
            else{
                return null
            }
        }
        return circularGet(metadatas, 0)
    }

    defineHrefLang(url){

        var element = Array.from(this.document.head['children']).find(e => e['rel'] && e['rel'] == "canonical")
        var index = Array.from(this.document.head['children']).indexOf(element)
        if(element && index>=0){
            this.document.head['children'][index].setAttribute('href', environment.websiteURL.slice(0,-1)+url);
        }
        for(let lang of ['fr','en', 'x-default']){
            var element = Array.from(this.document.head['children']).find(e => e['rel'] && e['rel'] == "alternate" && e['hreflang'] && e['hreflang'] == lang)
            var index = Array.from(this.document.head['children']).indexOf(element)
            if(element && index>=0){
                this.document.head['children'][index].setAttribute('href', environment.websiteURL.slice(0,-1)+this.language.getRouteInOtherLang(((lang=='x-default') ? 'en' : lang)));
            }
        }

    }

}
