<footer class="" (click)="technicalSupportOpen = false">
    <div class="help" *ngIf="pageIsNotAboutUs" >

        <contact-us-btn text="" paddings='px-4 pt-0'></contact-us-btn>

    </div>
    <div class="container py-5 second-container">
        <div class="row">
            <div class="col-12 logo-footer">
              <div class="d-flex text-center justify-content-center align-items-center">
                  <picture>
                      <source srcset="/assets/logo_flat_texteblanc_45.webp" type="image/webp">
                      <source srcset="/assets/logo_flat_texteblanc_45.png" type="image/png">
                      <img class="logo mr-3" src="/assets/logo_flat_texteblanc_45.png" alt="Logo of Immersive Factory" title="Immersive Factory"  loading="lazy"/>
                  </picture>
                </div>
                <div class="social my-3">

                    <a class="twitter mx-1" (click)="$event.stopPropagation()" rel="noopener nofollow" target="_blank" href="https://twitter.com/i/redirect?url=https%3A%2F%2Ftwitter.com%2Fimmersivefactor%3Fcn%3DYWRkcmVzc19ib29rX2NvbnRhY3RfYWRkZWQ%253D%26refsrc%3Demail&amp;t=1&amp;cn=YWRkcmVzc19ib29rX2NvbnRhY3RfYWRkZWQ%3D&amp;sig=b3720891e99093100f692c33437d35c222c28d94&amp;iid=28b87680a2ab4f3d86ee060288204c12&amp;uid=121430916&amp;nid=60+1110" title="twitter">
                        <img src="/assets/twitter.svg" alt="Logo Twitter" title="twitter" loading="lazy">
                    </a>
                    <a class="linkedin mx-1" (click)="$event.stopPropagation()" rel="noopener nofollow" target="_blank" href="https://www.linkedin.com/company/immersive-factory" title="Linkedin">
                        <img src="/assets/linkedin.svg" alt="Logo Linkedin" title="linkedin" loading="lazy">
                    </a>
                    <a class="youtube mx-1" (click)="$event.stopPropagation()" rel="noopener nofollow" target="_blank" href="https://www.youtube.com/channel/UCSVDmC2pRJoDNy_-obuf7ZA/featured" title="Youtube">
                        <img src="/assets/youtube.svg" alt="Logo YouTube" title="youtube" loading="lazy">
                    </a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center mt-4">
                <ul class="list-unstyled text-small list-group list-group-horizontal">
                    <li class="mr-2 text-muted"><a class="text-muted pr-2" [routerLink]="language.navigateAccordingToLang(['/about-us'])" href="#" title="about us">{{'Qui sommes-nous' | translate}}</a> | </li>
                    <li class="mr-2 text-muted"><a class="text-muted pr-2" [routerLink]="language.navigateAccordingToLang(['/jobs'])" title="careers">{{'Recrutement' | translate}}</a> | </li>
                    <li class="mr-2 text-muted"><a class="text-muted" [routerLink]="language.navigateAccordingToLang(['/research-innovation'])" title="R&D">{{'R&D' | translate}}</a></li>

                </ul>
            </div>
        </div>
    </div>
    <div class="container py-2">
        <div class="d-flex justify-content-around">
            <div class="mb-3 text-muted"><strong>&copy; Immersive Factory</strong> - {{"Tous droits réservés" | translate}} - <a class="text-muted" role="button" [routerLink]="language.navigateAccordingToLang(['/legal'])" title="legal notice">{{'Mentions légales' | translate}}</a></div>
        </div>
    </div>

</footer>
