export const metadatas = {
	"checkout": {
		googlebot: "noindex",
		robots: "noindex"
	},
	"basket": {
		googlebot: "noindex",
		robots: "noindex"
	},
	"my-account": {
		title: "My account - Immersive Factory",
		description: "",
		googlebot: "noindex",
		robots: "noindex",
		subs: {
			"*": {
				title: "My account - Immersive Factory",
				description: "",
				googlebot: "noindex",
				robots: "noindex"
			}
		}
	},
    "downloads": {
		title: "Downloads - Immersive Factory",
		description: "",
		googlebot: "noindex",
		robots: "noindex",
	},
	"blog": {
		title: "Blog - News about VR and immersive learning",
		description: "News, tips and tricks to learn all about immersive learning and virtual reality on the blog of Immersive Factory experts",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/blog.jpg",
		subs: {
			"*": {
				title: "The Immersive Factory Blog",
				description: "News, tips and tricks to learn all about immersive learning and virtual reality on the blog of Immersive Factory experts",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/blog.jpg",
			}
		}
	},
	"research-innovation": {
		title: "Measure the impact and create value with fun education",
		description: "Our R&D is involved in innovative research projects to democratize and promote learning techniques through playful pedagogy",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/rd.jpg",
		subs: {
			"*": {
				title: "Measure the impact and create value with fun education",
				description: "Our R&D is involved in innovative research projects to democratize and promote learning techniques through playful pedagogy",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/rd.jpg",
			}
		}
	},
	"demo": {
		title: "Test our demo for free without registration or installation",
		description: "Test an online free excerpt of our Hazard Spotting - Warehouse workshop on Windows PC & Mac Viewer. Become actor of your training to reach Zero accident goal!",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/demo-hazard-spotting.jpg",
	},
	"safetybox-vr": {
		title: 'Opt for a turnkey SafetyBoxVR® and get a ready-to-use VR headset',
		description: 'Immersive Factory offers you to take control of your training and animation schedule by renting you a VR headset for one year',
		image: 'https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/safetybox.jpg',
	},
	"security-welcome": {
		title: 'Your turnkey security welcome using virtual reality',
		description: 'Virtual reality for your onboarding: put the newcomers directly in risky situations and confront them with dangers in realistic immersive environments',
		image: 'https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/security-welcome.jpg',
	},
	"vr-training-osh": {
		title: 'OSH Virtual Reality training - Health and Safety at work',
		description: 'Discover our OSH training programmes in virtual reality dedicated to health and safety at work. Explore our first aid training solutions in VR',
		image: 'https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/vr-training.jpg',
	},
	"safety-day": {
		title: "Safety Day at Work - Prevent risks in the workplace!",
		description: "Safety Day by Immersive Factory! Take part in a unique experience thanks to our interactive VR training courses to improve safety in the workplace",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/journee-securite.jpg",
        subs: {
            "*": {
                title: "Safety Day at Work - Prevent risks in the workplace!",
                description: "Safety Day by Immersive Factory! Take part in a unique experience thanks to our interactive VR training courses to improve safety in the workplace",
                image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/journee-securite.jpg",
            }
        }
	},
	"virtual-campus": {
		title: "Prolong your safety days using a virtual EHS campus",
		description: "Immersive Factory works to raise your teams’ awareness around professional risk issues through a fully immersive digital safety day",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/safedy_day.jpg",
	},
	"digital-learning": {
		title: "Digital learning - Immersive digital training expertise",
		description: "Explore our digital training courses dedicated to workplace safety. Explore Immersive Factory's expertise in digital learning to reinforce safety prevention.",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/digital_learning.jpg",
	},

	"safety-quarter-hour": {
		title: "Safety quarter hour: workplace safety training in VR",
		description: "Discover our safety quarter hour training courses to promote safety in the workplace. Immersive modules to improve safety culture in the workplace.",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/15_Minute_Safety_Session.jpg",
	},
	"press-releases": {
		title: "Immersive Factory - Publications and press releases",
		description: "Discover all our publications and press releases about Immersive Factory, virtual reality, ludo pedagogy and immersive learning",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/press_releases.jpg",
	},
	"events": {
		title: "Discover all our past and upcoming events",
		description: "An overview of past and upcoming events and webinars discussing e-learning, gamification, ludopedagogy, VR and their implementation in the EHS industry",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/events.jpg",
	},
	"white-papers": {
		title: "The Immersive Factory white papers",
		description: "An overview of white papers including academic articles on e-learning, immersive learning, gamification, ludopedagogy and VR",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/white_papers.jpg",
	},
	"industry-health": {
		title: "EHS analysis of the health sector",
		description: "Key figures of the health sector and advantages of virtual reality and immersive learning applied to the health industry",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/sector-health.jpg",
	},
	"industry-waste-management": {
		title: "EHS analysis of the waste management sector",
		description: "Key figures of the waste management sector and advantages of virtual reality and immersive learning applied to the waste management industry",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/sector-waste.jpg",
	},
	"industry-university": {
		title: "EHS analysis of the university sector",
		description: "Key figures of the university sector and advantages of virtual reality and immersive learning applied to the university sector",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/sector-university.jpg",
	},
	"industry-food": {
		title: "EHS analysis of the food sector",
		description: "Key figures of the food sector and advantages of virtual reality, ludo pedagogy and immersive learning applied to the food industry",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/food_sector.jpg",
	},
	"industry-construction": {
		title: "EHS analysis of the construction sector",
		description: "Key figures of the construction sector and advantages of virtual reality and immersive learning applied to the construction industry",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/Construction_Sector.jpg",
	},
	"industry-pharmaceutical": {
		title: "EHS analysis of the pharmaceutical sector",
		description: "Key figures of the pharmaceutical sector and advantages of virtual reality and immersive learning applied to the pharmaceutical industry",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/pharmaceutical_sector.jpg",
	},
	"industry-road-safety": {
		title: "EHS analysis of the road safety sector",
		description: "Key figures of the road safety sector and advantages of virtual reality and immersive learning applied to the road safety industry",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/motorways_sector.jpg",
	},
	"industry-manufacturing": {
		title: "EHS analysis of the manufacturing sector",
		description: "Key figures of the manufacturing sector and advantages of virtual reality and immersive learning applied to the manufacturing industry",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/manufacturing_sector.jpg",
	},
	"industry-logistics": {
		title: "EHS analysis of the logistics sector",
		description: "Key figures of the logistics sector and advantages of virtual reality and immersive learning applied to the logistics industry to overcome challenges",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/logistics_sector.jpg",
	},
	"industry-petrochemicals": {
		title: "EHS analysis of the petrochemicals sector",
		description: "Key figures of the petrochemicals sector and advantages of virtual reality and immersive learning applied to the petrochemicals industry to overcome challenges",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/petrochemicals_sector.jpg",
	},
	"faq": {
		title: "Support and FAQ - All your questions and answers",
		description: "All the answers to your questions about the installation and use of VR headsets, our workshops calogue, the SafetyBoxVR®, the training campus and the metaverse",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/faq.jpg",
	},
	"content": {
		title: "Content of hygiene, safety, environment training",
		description: "Digital and immersive EHS training available on subscription for all. Safety training in virtual reality, 3D on PC, tablet, mobile",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/content.jpg",
		subs: {
			"*": {
				title: "Content of hygiene, safety, environment training",
				description: "Digital and immersive EHS training available on subscription for all. Safety training in virtual reality, 3D on PC, tablet, mobile",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/content.jpg",
			}
		}
	},
	"about-us": {
		title: "Aim for educational excellence with immersive learning",
		description: "Created by experts in immersive learning, Immersive Factory launches virtual online campuses for EHS training and events",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/about_us.jpg",
		subs: {
			"o": {
				title: "Aim for educational excellence with immersive learning",
				description: "Created by experts in immersive learning, Immersive Factory launches virtual online campuses for EHS training and events",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/about_us.jpg",
			}
		}
	},
	"echangeformation": {
		title: "Echange Formation - QHSE Audit, Training & Consulting",
		description: "Echange Formation brings together experts who are dedicated, available and committed to sharing their know-how with you, in a spirit of listening and proximity",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/echangeformation.jpg",
		subs: {
			"o": {
				title: "Echange Formation - QHSE Audit, Training & Consulting",
				description: "Echange Formation brings together experts who are dedicated, available and committed to sharing their know-how with you, in a spirit of listening and proximity",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/echangeformation.jpg",
			}
		}
	},
	"interviews": {
		title: "Interviews and customer testimonials",
		description: "Interviews and customer testimonials to find out how Immersive Factory supports companies in various sectors on a daily basis",
		keywords: "interviews testimonials",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/interviews.jpg",
	},
	"our-contributors": {
		title: "Our contributors",
		description: "More than 30 major accounts have participated in the development of our QEHS workshops in virtual reality",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/our-contributors.jpg",
	},
	"prices": {
		title: "Platform subscription prices",
		description: "Discover the subscription prices available on the IMMERSIVE FACTORY platform",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/subscription.jpg",
	},
	"jobs": {
		title: "Current job offer and recruitment at IMMERSIVE FACTORY",
		description: "Join the IMMERSIVE FACTORY team and together let's reinvent the future of EHS VR training. Apply online by selecting an ad",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/recruitment.jpg",
		subs: {
			"*": {
				title: "Current job offer and recruitment at IMMERSIVE FACTORY",
				description: "Join the IMMERSIVE FACTORY team and together let's reinvent the future of EHS VR training. Apply online by selecting an ad",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/recruitment.jpg",
			}
		}
	},
	"legal": {
		title: "General conditions of sale and legal notices",
		description: "Discover the general conditions of sale in force since 01/01/2019. We hope you will enjoy the Immersive Factory EHS solutions",
		image: null,
	},
	"partners": {
		title: "Immersive Factory - Become a reseller partner",
		description: "Partners - Immersive Factory selects and trains partners, approved experts, to support you in your VR projects",
		image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/partners.jpg",
	},

	"fr": {
		title: "Formation réalité virtuelle pour les équipes à distance et sur site",
		description: "Découvrez nos solutions de formation réalité virtuelle. Transformez votre approche pédagogique avec des expériences de formation VR",
		subs: {
			"checkout": {
				googlebot: "noindex",
				robots: "noindex"
			},
			"basket": {
				googlebot: "noindex",
				robots: "noindex"
			},
			"my-account": {
				title: "Mon compte - Immersive Factory",
				description: "",
				googlebot: "noindex",
				robots: "noindex",
				subs: {
					"*": {
						title: "Mon compte - Immersive Factory",
						description: "",
						googlebot: "noindex",
						robots: "noindex"
					}
				}
			},
            "downloads": {
                title: "Téléchargements - Immersive Factory",
                description: "",
                googlebot: "noindex",
                robots: "noindex",
            },
			"actualites": {
				title: "Blog - Actualités sur la VR et l'apprentissage immersif",
				description: "Des actualités, conseils et astuces pour tout savoir sur l'apprentissage immersif et la réalité virtuelle sur le blog des experts d'Immersive Factory",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/blog.jpg",
				subs: {
					"*": {
						title: "Le Blog Immersive Factory ",
						description: "Des actualités, conseils et astuces pour tout savoir sur l'apprentissage immersif et la réalité virtuelle sur le blog des experts d'Immersive Factory",
						image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/blog.jpg",
					}
				}
			},
			"recherche-innovation": {
				title: "Animation ludique sécurité au travail - Ludopédagogie sécurité",
				description: "Découvrez nos pôles ludiques de R&D pour vous aider à mieux appréhender les risques d'accidents au travail. Formez vos équipes grâce à la réalité virtuelle.",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/rd.jpg",
				subs: {
					"*": {
						title: "Animation ludique sécurité au travail - Ludopédagogie sécurité",
						description: "Découvrez nos pôles ludiques de R&D pour vous aider à mieux appréhender les risques d'accidents au travail. Formez vos équipes grâce à la réalité virtuelle.",
						image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/rd.jpg",
					}
				}
			},
			"safetybox-vr": {
				title: 'SafetyBoxVR® - Bénéficiez d’un casque VR prêt à l’emploi',
				description: 'Immersive Factory vous propose de prendre en main votre planning de formation et d’animations en vous mettant à disposition un casque VR pendant un an',
				image: 'https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/safetybox.jpg',
			},
			"accueil-securite": {
				title: 'Votre accueil sécurité clé en main avec la réalité virtuelle',
				description: 'La réalité virtuelle pour vos onboardings : mettez directement en situation les nouveaux arrivants et confrontez les à des situations à risques au sein d’environnements immersifs réalistes',
				image: 'https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/security-welcome.jpg',
			},
			"formation-vr-sst": {
				title: 'Formation SST Réalité Virtuelle - Santé et Sécurité au travail',
				description: 'Découvrez nos programmes de formation SST en réalité virtuelle dédiés à la santé et sécurité au travail. Explorez nos solutions de formation en secourisme en VR',
				image: 'https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/vr-training.jpg',
			},
			"journee-securite": {
				title: "Journée Sécurité au Travail - Prévenez les risques en Entreprise !",
				description: "Journée Sécurité par Immersive Factory ! Participez à une expérience unique grâce à nos formations interactives en VR pour améliorer la sécurité en entreprise",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/journee-securite.jpg",
                subs: {
					"*": {
                        title: "Journée Sécurité au Travail - Prévenez les risques en Entreprise !",
                        description: "Journée Sécurité par Immersive Factory ! Participez à une expérience unique grâce à nos formations interactives en VR pour améliorer la sécurité en entreprise",
                        image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/journee-securite.jpg",
					}
				}
			},
			"campus-virtuel": {
				title: "Analyse secteur : e learning hse sur un campus virtuel",
				description: "Sensibilisez vos équipes aux risques professionnels grâce à notre formation en e learning hse sur un campus virtuel 100% immersif",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/safedy_day.jpg",
			},
			"formation-digitale": {
				title: "Digital learning - Expertise en Formations Digitales immersives",
				description: "Explorez nos formations digitales dédiées à la sécurité au travail. Explorez l’expertise d’Immersive Factory en digital learning pour renforcer la prévention sécurité.",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/digital_learning.jpg",
			},
			"quart-heure-securite": {
				title: "Quart d’heure sécurité : formation sécurité au travail en VR",
				description: "Découvrez nos formations Quart d’Heure Sécurité pour promouvoir la sécurité au travail. Des modules immersifs pour améliorer la culture sécurité en entreprise.",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/15_Minute_Safety_Session.jpg",
			},
			"communiques-de-presse": {
				title: "Immersive Factory - Publications et communiqués de presse",
				description: "Découvrez toutes nos publications et communiqués de presse sur Immersive Factory, la réalité virtuelle, la ludopédagogie et l’apprentissage immersif",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/press_releases.jpg",
			},
			"evenements": {
				title: "Découvrez tous nos évènements passés et à venir",
				description: "Un aperçu des évènements et webinaires passés et à venir sur l’e-learning, la gamification, la ludopédagogie, la VR et leur mise en œuvre dans l'industrie HSE",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/events.jpg",
			},
			"livres-blancs": {
				title: "Les livres blancs Immersive Factory",
				description: "Un aperçu de nos livres blancs comportant des articles académiques sur l'e-learning, l'apprentissage immersif, la gamification, la ludopédagogie et la VR",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/white_papers.jpg",
			},
			"industrie-sante": {
				title: "Formation des professionnels de santé en réalité virtuelle",
				description: "Professionnels de santé, formez-vous grâce à la réalité virtuelle et apprenez-en plus sur les gestes pour mieux gérer vos patients",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/sector-health.jpg",
			},
			"industrie-gestion-dechets": {
				title: "Formation VR sur la gestion des déchets",
				description: "Formez-vous à la gestion des déchets grâce à nos modules de formation en réalité virtuelle : évitez les incendies, accidents...",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/sector-waste.jpg",
			},
			"industrie-universite": {
				title: "Formation et Apprentissage en réalité virtuelle",
				description: "Vous êtes à l'université ? Découvrez nos offres de formation et d'apprentissage en réalité virtuelle. Immergez-vous dans un secteur en plein essor !",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/sector-university.jpg",
			},
			"industrie-agroalimentaire": {
				title: "Formation agroalimentaire HSE en réalité virtuelle",
				description: "Formation agroalimentaire à découvrir en immersion totale grâce à la réalité virtuelle. Réduisez le nombre d'accidents dans ce secteur !",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/sector-food.jpg",
			},
			"industrie-construction": {
				title: "Elearning prevention btp - support pédagogique Construction / BTP",
				description: "Evitez les risques mortels dans le secteur du BTP grâce à notre formation en elearning prevention BTP. Formez vos collaborateurs dès maintenant",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/Construction_Sector.jpg",
			},
			"industrie-pharmaceutique": {
				title: "Formation industrie pharmaceutique en VR",
				description: "Formez vos équipes avec notre formation en industrie pharmaceutique en réalité virtuelle. Formation continue avec notre plateforme clé en main",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/pharmaceutical_sector.jpg",
			},
			"industrie-securite-routiere": {
				title: "Formation à la Sécurité Routière avec la réalité virtuelle",
				description: "Notre formation sécurité routière en réalité virtuelle dispose de tous les éléments nécessaires pour éviter les risques liés à la conduite.",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/motorways_sector.jpg",
			},
			"industrie-manufacturiere": {
				title: "Formation lean manufacturing en réalité virtuelle",
				description: "Formation complète en lead manufacturing et lead management. Formation dispensée en réalité virtuelle avec un large catalogue d'ateliers.",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/manufacturing_sector.jpg",
			},
			"industrie-logistique": {
				title: "Formation transport logistique en réalité virtuelle",
				description: "Accélérez votre niveau de compétences grâce à notre formation en logistique et transport en immersion totale. Large choix d'ateliers !",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/logistics_sector.jpg",
			},
			"industrie-petrochimie": {
				title: "Formation à la manipulation des produits chimiques en VR",
				description: "Formez vos équipes grâce à notre formation en VR dédiée sur notre campus virtuel et nos différentes mises en situation. Outil personnalisable !",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/petrochemicals_sector.jpg",
			},
			"faq": {
				title: "Support et FAQ - Toutes vos questions et réponses ",
				description: "Tout savoir sur l’installation et l'utilisation des casques VR, notre calogue d'ateliers, la SafetyBoxVR®, le campus formateur et le metaverse",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/faq.jpg",
			},
			"demo": {
				title: "Testez gratuitement notre démo sans inscription ni installation",
				description: "Testez gratuitement en ligne notre démo en identifiant les risques dans un entrepôt virtuel, sur PC Windows & Viewer Mac. Devenez acteur de votre formation pour un objectif zéro accident !",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/demo-hazard-spotting.jpg",
			},
			"content": {
				title: "Contenus des formations hygiène, sécurité, environnement",
				description: "Des formations HSE digitales et immersives accessible sur abonnement pour tous. Formation sécurité en realité virtuelle, 3D sur PC, tablette, mobile ",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/content.jpg",
				subs: {
					"*": {
						title: "Contenus des formations hygiène, sécurité, environnement",
						description: "Des formations HSE digitales et immersives accessible sur abonnement pour tous. Formation sécurité en realité virtuelle, 3D sur PC, tablette, mobile ",
						image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/content.jpg",
					}
				}
			},
			"a-propos": {
				title: "Plateforme e learning - Visez l'excellence pédagogique en ligne",
				description: "Plateforme e learning créée par des experts de l'immersive learning, Immersive Factory lance des campus virtuels online pour la formation et les évènements HSE",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/about_us.jpg",
				subs: {
					"o": {
						title: "Plateforme e learning - Visez l'excellence pédagogique en ligne",
						description: "Plateforme e learning créée par des experts de l'immersive learning, Immersive Factory lance des campus virtuels online pour la formation et les évènements HSE",
						image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/about_us.jpg",
					}
				}
			},
			"echangeformation": {
				title: "Echange Formation - Audit, Formation & Conseil QHSE",
				description: "Echange Formation réunit des experts dédiés, disponibles et engagés pour vous transmettre leur savoir-faire avec écoute et proximité",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/echangeformation.jpg",
				subs: {
					"o": {
						title: "Echange Formation - Audit, Formation & Conseil QHSE",
						description: "Echange Formation réunit des experts dédiés, disponibles et engagés pour vous transmettre leur savoir-faire avec écoute et proximité",
						image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/echangeformation.jpg",
					}
				}
			},
			"interviews": {
				title: "Interviews, entretiens et témoignages clients",
				description: "Des interviews, entretiens et témoignages clients pour découvrir comment Immersive Factory accompagne au quotidien les entreprises de différents secteurs",
				keywords: "interviews temoignages",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/interviews.jpg",
			},
			"nos-contributeurs": {
				title: "Nos contributeurs",
				description: "Plus de 30 grands comptes ont participé au développement de nos ateliers QHSE en réalité virtuelle",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/our-contributors.jpg",
			},
			"tarif": {
				title: "Tarifs des abonnements de la plateforme ",
				description: "Découvrez les tarifs des abonnements disponible sur la plateforme IMMERSIVE FACTORY ",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/subscription.jpg",
			},
			"recrutement": {
				title: "Offre d'emplois et Recrutement en cours chez IMMERSIVE FACTORY ",
				description: "Rejoignez l'équipe d'IMMERSIVE FACTORY et réinventons ensemble le futur de la formation HSE. Postulez en ligne en sélectionnant une annonce ",
				image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/recruitment.jpg",
				subs: {
					"*": {
						title: "Offre d'emplois et Recrutement en cours chez IMMERSIVE FACTORY ",
						description: "Rejoignez l'équipe d'IMMERSIVE FACTORY et réinventons ensemble le futur de la formation HSE. Postulez en ligne en sélectionnant une annonce ",
						image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/recruitment.jpg",
					}
				},
				"legal": {
					title: "Conditions générales de vente et Mentions légales",
					description: "Les conditions générales de ventes en vigueur depuis le 1/01/2019. Nous espérons que vous profiterez bien des solutions HSE d'Immersive Factory",
					image: null,
				},
				"partenaires": {
					title: "Immersive Factory - Devenir partenaire revendeur ",
					description: "Partenaires - Immersive Factory sélectionne et forme des des partenaires, experts agréés, pour vous accompagner dans vos projets VR",
					image: "https://immersivemisc.s3.eu-west-3.amazonaws.com/seo/original/partners.jpg",
				},
			}
		}
	}
}
