import { Component, OnInit,ChangeDetectorRef, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd, RoutesRecognized, UrlSegmentGroup } from '@angular/router';
import { UtilsService } from './shared/services/utils.service';
import { AppService } from './shared/services/app.service';
import { AccountService } from './shared/services/account.service';
import { NotificationsService } from './shared/services/notifications.service'
import { MetadatasService } from './shared/services/metadatas.service'
import { LanguageService } from './shared/services/language.service'
import { filter, map, mergeMap, pairwise } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';


import {isBrowser} from './shared/thirdparty/isBrowser'

import { environment } from '../environments/environment'
import { LocalstorageService } from './shared/services/localstorage.service';

declare var $:any;
declare var window:any

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    public routeSub:any = null;

    previousUrl = null

    constructor(
        private router: Router,
        public utilsService: UtilsService,
        public appService: AppService,
        public accountService:AccountService,
        private cdRef:ChangeDetectorRef,
        public notifications : NotificationsService,
        public metadatasService : MetadatasService,
        public language : LanguageService,
        private activatedRoute: ActivatedRoute,
        private localStorage: LocalstorageService,
        @Inject(DOCUMENT) public document: Document
    ) {

    }

    ngAfterViewChecked(){
        // this.dateNow = new Date();
        this.cdRef.detectChanges();
    }

    ngOnInit() {
        if(isBrowser() && !this.previousUrl){
            this.previousUrl = location.pathname
        }
        if(isBrowser()){
            document.addEventListener('mousedown', e => {
                if(e.target['id'] && e.target['id'] == 'loginBackModal'){
                    this.appService.popupAccount()
                }
            });
        }


        var lang = 'en'

        if(
            this.document &&
            this.document.location
        ){
            if(
                this.document.location['pathname'] &&
                this.document.location['pathname'] == '/my-account'
            ){
                this.document.location['href'] = environment['accountURL']
            }
            else if(
                this.document.location['pathname'] &&
                this.document.location['pathname'] == '/fr/my-account'
            ){
                this.document.location['href'] = environment['accountURL']+'/fr'
            }
            else if(
                this.document.location['href'] &&
                this.document.location['href'].slice(-3) == '/fr' ||
                this.document.location['href'].includes('/fr/')
            ){
                lang = 'fr'
            }
        }

        this.language.setLang(lang)

        if(isBrowser() && typeof document != 'undefined' && document.location.href.indexOf("localhost")==-1 && console){
            console.log = function(){}
        }

        this.router.events.subscribe((evt) => {

            if(evt instanceof NavigationEnd){
                this.metadatasService.setMetadatas(this.router.url)

                if(isBrowser() && document && window && typeof document != 'undefined' && typeof window != 'undefined'){
                    var urlToCompare = this.router.url.toString().split('?')[0]

                    if(this.previousUrl != urlToCompare){
                        window.scrollTo(0,0)
                    }
                }
                this.previousUrl = urlToCompare
            }

            var params = this.activatedRoute.snapshot.queryParamMap['params']

            if(params['utm_source']){
              localStorage.setItem('last_utm_source', params['utm_source'])
            }
            if(params['utm_medium']){
              localStorage.setItem('last_utm_source_detail', params['utm_medium'])
            }

            if(isBrowser()) $('.swal2-container').remove()
            this.utilsService.changeBodyClass("");
        });

        if(isBrowser() && this.localStorage.getItem('AuthToken')){
            this.accountService.loadAccountInfos()
            .then(()=>{
                this.initChat()
            });
        }
        else{
            this.initChat()
        }

    }

    checkIsBrowser(){
        return isBrowser()
    }

    initChat(){
        if(isBrowser() && document && window && typeof document != 'undefined' && typeof window != 'undefined'){
            var chatwootUrl = "https://livechat.immersivefactory.com";
            var token = 'Aq2qBmSzUvuz7QVNmHzBX6FH'

            window.chatwootSettings = {
                locale: this.language.lang,
                type: 'expanded_bubble', // [standard, expanded_bubble]
                launcherTitle: ((this.language.lang && this.language.lang=='fr') ? 'Discuter avec nous' : 'Chat with us')
            };

            ((d,t)=>{
                var g = d.createElement(t)
                var s = d.getElementsByTagName(t)[0]
                g['src'] = chatwootUrl+"/packs/js/sdk.js"
                s['parentNode'].insertBefore(g,s)
                window.addEventListener('chatwoot:ready', ()=>{
                    if(this.accountService.userInfos && this.accountService.userInfos['id']){
                        this.accountService.setChatwootInfos()
                    }
                });
                g.onload = ()=>{
                    window.chatwootSDK.run({
                        websiteToken: token,
                        baseUrl: chatwootUrl
                    })
                }
            })(document,"script");
        }
    }

    isBasketOrCheckout(){
        var url = this.router.url.replace('fr/','')
        return url == '/basket' || url == '/checkout' || url.includes('/checkout/')
    }

}
