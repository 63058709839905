import { Injectable,EventEmitter, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './app.service';
import { LocalstorageService } from './localstorage.service'
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DOCUMENT } from '@angular/common';

declare var $:any;
import {isBrowser} from '../thirdparty/isBrowser'
import { routes } from '../thirdparty/seo/lang-routes'
import { environment } from '../../../environments/environment';

import frLang from '../../../i18n/fr.json'
import enLang from '../../../i18n/en.json'


@Injectable({providedIn: 'root'})
export class LanguageService {


    public langChange: EventEmitter<string> = new EventEmitter();


    public fr: any = {};
    public en: any = {};
    public de: any = {};
    public es: any = {};
    public it: any = {};

    public langs = ["fr", "en"]

    public months = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre"]
    public shortMonths = {
        fr : ["Janv","Févr","Mars","Avril","Mai","Juin","Juil","Août","Sept","Oct","Nov","Déc"],
        en : ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"]
    }

    public shortDays = {
        fr : ["Dim","Lun","Mar","Mer","Jeu","Ven","Sam"],
        en : ["Sun","Mon","Tues","Wed","Thurs","Fri","Sat"]
    }



    public lang = null

    constructor(
        public translate: TranslateService,
        public appService : AppService,
        public localStorage : LocalstorageService,
        public router : Router,
        public http : HttpClient,
        @Inject(DOCUMENT) public document: Document
    ) {

    }

    setLang(lang){
        const langs = {
            fr: frLang,
            en: enLang
        }
        this.lang = lang
        this.translate.setTranslation(lang,langs[lang])
        this.translate.use(lang)
    }

    changeLanguage(lang, refresh = true){
        if(isBrowser()) window.location.href = this.getRouteInOtherLang(lang)
    }

    getRouteInOtherLang(lang,url=this.router.url.slice(1)){
        var urlSplitted = url.split('?')[0].split('/')
        if(this.lang != 'en'){
            urlSplitted.shift()
        }
        var futureUrl:any = "/"
        for(let oRoute of Object.keys(routes)){
            if(routes[oRoute][this.lang] && routes[oRoute][this.lang]==urlSplitted.join('/')){
                futureUrl = oRoute.split('/')
            }
            else if(routes[oRoute][this.lang] && routes[oRoute][this.lang]==urlSplitted[0]){
                futureUrl = JSON.parse(JSON.stringify(urlSplitted))
                futureUrl[0] = oRoute
            }
            else if(routes[oRoute]['en'] && routes[oRoute]['en']==urlSplitted[0]){
                futureUrl = JSON.parse(JSON.stringify(urlSplitted))
                futureUrl[0] = oRoute
            }
        }
        return this.navigateAccordingToLang(futureUrl, lang)[0]

    }

    getDate(date, formatted=true, separator='-'){
        var newDate = new Date(date)
        var lang = this.lang
        if(formatted == true){
            return newDate.toLocaleString(lang, { year: 'numeric', month: 'long', day: 'numeric'});
        }
        else{
            var day = newDate.getDate().toString().length > 1 ? newDate.getDate().toString() : '0' + newDate.getDate().toString();
            var month = (newDate.getMonth()+1).toString().length > 1 ? (newDate.getMonth()+1).toString() : '0' + (newDate.getMonth()+1).toString();
            var year = newDate.getFullYear()

            if(lang == "en"){
                return [year,month,day].join(separator)
            }
            else{
                return [day,month,year].join(separator)
            }
        }

    }

    i18nGetAttr(attr){
        if(this.lang != "fr"){
            return attr+'_'+this.lang
        }
        else{
            return attr
        }
    }

    navigateAccordingToLang(route, lang=this.lang){
        if(route.length && route[0].slice(0,3) == '/fr'){ route[0] = route[0].slice(3) }
        var start = '/'+((lang && lang != 'en') ? lang : '')
        var futureRoute = Array.isArray(route) ? route.join('/') : route.toString()
        if(futureRoute.slice(0,1)=='/'){ futureRoute = futureRoute.slice(1) }

        var toReturn = start+'/'+futureRoute
        var routeKey = ""
        if(routes[futureRoute]){
            routeKey = futureRoute
        }
        else if(Object.keys(routes).includes(futureRoute.split('/')[0])){
            routeKey = futureRoute.split('/')[0]
        }

        if(routeKey){
            if(lang != 'en' && routes[routeKey][lang]){
                toReturn = start+'/'+futureRoute.replace(routeKey, routes[routeKey][lang])
            }
            else{
                toReturn = start+'/'+futureRoute.replace(routeKey, routes[routeKey]['en'])
            }
        }

        if(toReturn.slice(-1)=='/'){ toReturn = toReturn.slice(0,-1) }
        if(toReturn.slice(0,2)=='//'){ toReturn = toReturn.slice(1) }
        if(toReturn.includes('/fr/my-account')){ toReturn = toReturn.replace('/fr/my-account', '/my-account/fr') }
        return [toReturn]
    }

    getAccountink(url, translate=true){
        var fronUrl = environment['accountURL']
        if(fronUrl.slice(-1) == '/'){
            fronUrl = fronUrl.slice(0,-1)
        }

        return fronUrl+((translate) ? this.navigateAccordingToLang([url]) : '/'+url)
    }

}
