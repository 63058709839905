<div id="app" *ngIf="checkIsBrowser()">
    <div class="app-content" [ngClass]="{'grayBg' : isBasketOrCheckout()}">
        <app-header></app-header>
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

</div>

<app-header-popup *ngIf="appService.popuped && checkIsBrowser()"></app-header-popup>

<!-- <app-cookies-warning></app-cookies-warning> -->

<div *ngIf="!checkIsBrowser()">
    <router-outlet></router-outlet>
</div>
